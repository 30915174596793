import './App.css';
import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import ReactTooltip from 'react-tooltip';
import Web3 from 'web3';
import sha256 from 'crypto-js/sha256';
import Decdoc from './Decdoc.json';

const chainId_Final = '0x1';



class Ultima extends React.Component{


  constructor(props){
    super(props);
    this.state ={
      showAddSpecific:false,
      showCommon:false,
      orgDescAdded:false,
      showCheckSpecific:false,
      accountAddress:"0x0",
      isAdmin:false,
      adminAddress:0x0,
      issuerDesc:"",
      isPremium:false,
      descPrice:0,
      contract:null,
      contractBalance:0,
      contractAddress:'0x0',
      errorSet:false,
      errorMsg:"",
      showJSONformat:false,
      outputTable:false,
      outputTableData:[],
      submitevent:null,
      successSet:false,
      successMsg:"",
      isLoading:false,
      askVal:Web3.utils.toBN(Number('1e15')),
      buttonStyleCommon:{textAlign:'center',
                    fontWeight: 'Helvetica', 
                    fontSize:'20px', 
                    cursor: 'pointer',
                    width:this.getButtonWidth(),
                    marginTop:'2pc',
                    backgroundColor:'white',
                   },
      addButtonStyle:{},
      checkButtonStyle:{},
      //checkButtonStyle:this.state.buttonStyleCommon,
    };
    this.ShowAdd();
    this.CheckAdd();
  }

  getButtonWidth = (x=0)=>{
		let output = String((((window.innerWidth*1.0)/window.innerHeight)*280)-x)+'px';
		console.log(output);
		return output;
	}

  LoadPremium = async(address)=>{
    if (this.state.contract === null || address === "0x0"){
    	this.setState({isPremium:false});
      return;
    }

    this.setState({isPremium: await this.state.contract.methods.showDescriptions(this.state.accountAddress).call()});    
  }

  LoadIssuer =async (address)=>{
    if (this.state.contract === null || address === "0x0"){
    	this.setState({orgDescAdded:false});
      return;
    }

    this.setState({issuerDesc : await this.state.contract.methods.issuerDesc(this.state.accountAddress).call()});
    this.setState({orgDescAdded: await this.state.contract.methods.IsIssuerDataPresent(this.state.accountAddress).call()});
  }

  LoadContractBalance = async ()=> {
    if (this.state.contract === null){
      return;
    }

    this.setState({contractBalance: Web3.utils.fromWei(await this.state.contract.methods.balanceOfContract().call({from:this.state.accountAddress}))});
  }

  LoadDescPrice = async ()=>{
    if (this.state.contract === null){
      return;
    }

    this.setState({descPrice:await this.state.contract.methods.descPrice().call()});
  }

  async accountAddedPost(){
  	if(window.ethereum === undefined){
      window.alert('install metamask wallet');
      return;
    }
    await this.loadWeb3()
    await this.loadBlockChainData()
    window.ethereum.on('accountsChanged', (account)=>{
    	if(account.length === 0){
    		account = "0x0";
    	}
    	//console.log(account);
      this.setState({accountAddress:String(account).toLowerCase()})
      this.setState({isAdmin:(this.state.accountAddress === this.state.adminAddress)});
      this.LoadPremium(this.state.accountAddress);
      //this.LoadIssuer(this.state.accountAddress);
    })

    window.ethereum.on('chainChanged', (chainId)=>{
        //this.ShowError("network changed, please change network back to mainnet.");
        this.loadBlockChainData()
    })
  }

  async componentDidMount(){
  	//await this.accountAddedPost();
  }

  async componentWillUnmount(){
  	//window.ethereum.removeListener('accountsChanged', ()=>{void});
  	//window.ethereum.removeListener('chainChanged', ()=>{});
  }

  async loadWeb3(){
    if(window.ethereum){
      window.web3 = new Web3(window.ethereum)
    }else{
      window.alert('install metamask wallet');
    }
  }

  async loadBlockChainData(){

    if(!window.ethereum){
      return;
    }
    
    const account = await window.ethereum.request({ method: 'eth_requestAccounts' });
    this.setState({accountAddress: String(account).toLowerCase()});

    const networkId = await window.ethereum.request({method: 'net_version'});
    const networkData = Decdoc.networks[networkId];
    if(networkData) {
      const abi = Decdoc.abi;
      const address = networkData.address;
      this.setState({contractAddress:address});
      var contract = new window.web3.eth.Contract(abi, address);
      this.setState({contract});
      this.setState({adminAddress: await contract.methods.admin().call()});
      this.setState({adminAddress: await this.state.adminAddress.toLowerCase()});
      this.setState({isAdmin:(this.state.accountAddress === this.state.adminAddress)});
      this.LoadDescPrice();
      this.LoadContractBalance();
      this.LoadPremium(this.state.accountAddress);
      this.LoadIssuer(this.state.accountAddress);
    } else {
      this.setState({contract:null});
      try{
        //window.alert('contract not active on this network, please change network to ethereum mainnet in metamask');
        let switched = await window.ethereum.request({method:'wallet_switchEthereumChain', 
                               params:[{chainId:chainId_Final}]});
        //console.log(switched);
        this.loadBlockChainData();
      }
      catch(error){
        this.ShowError(error.message);
      }
    }
  }

  //=> init of blockchain done.
  ShowAdd = ()=>{
    this.state.addButtonStyle = this.state.buttonStyleCommon;
    this.state.addButtonStyle.borderColor = 'lightgrey';
    this.state.addButtonStyle.color = 'grey';
  }

  CheckAdd = ()=>{
    this.state.checkButtonStyle = this.state.buttonStyleCommon;
    this.state.checkButtonStyle.borderColor = 'lightgrey';
    this.state.checkButtonStyle.color = 'grey';
  }

  ShowError = (msg, duration=3)=>{
    let prefix = "Error: ";
    if(msg !== undefined){
      msg = prefix + msg.toString()
    }else{
      msg = prefix;
    }
    this.setState({errorSet:true, errorMsg:msg.substring(msg.indexOf(":"))});
    setTimeout(()=>{this.setState({errorSet:false, errorMsg : ""});}, duration * 1e3);
  }

  ShowSuccess = (msg, duration=3)=>{
    this.setState({successSet:true, successMsg:msg.substring(msg.indexOf(":"))});
    setTimeout(()=>{this.setState({successSet:false, successMsg : ""});}, duration * 1e3);
  }

  onClickAdd = ()=>{
    
    if (this.state.contract === null){
      this.ShowError("connect to ethereum");
      return;
    }
    
    let copy = JSON.parse(JSON.stringify(this.state.addButtonStyle));
                           copy.color = 'darkorange';
                           copy.borderColor = 'grey';
                           this.setState({addButtonStyle:copy});
    copy = JSON.parse(JSON.stringify(this.state.checkButtonStyle));
                           copy.color = 'gray';
                           copy.borderColor = 'lightgrey';
                           this.setState({checkButtonStyle:copy});
    this.setState({showAddSpecific:true, showCommon:true, showCheckSpecific:false});
  }

  onClickEditOrgDesc = ()=>{
    this.setState({showAddSpecific:true, showCommon:true, showCheckSpecific:false});
  }

  onClickCheck = ()=>{
    if(window.ethereum === undefined){
      window.alert('install metamask wallet');
      return;
    }
    if (this.state.contract === null){
    	this.ShowError("connect to ethereum");
      return;
    }

    let copy = JSON.parse(JSON.stringify(this.state.addButtonStyle));
                           copy.color = 'gray';
                           copy.borderColor = 'lightgrey';
                           this.setState({addButtonStyle:copy});
    copy = JSON.parse(JSON.stringify(this.state.checkButtonStyle));
                           copy.color = 'darkorange';
                           copy.borderColor = 'grey';
                           this.setState({checkButtonStyle:copy});
    this.setState({showAddSpecific:false, showCommon:true, showCheckSpecific:true, isLoading:false});
  }

  AddOrgDescOnClick = async (event)=>{
    if (this.state.contract === null){
      return;
    }
    event.preventDefault();
    if(this.state.contract === null){
      window.location.reload();
      return;
    }
    const data = new FormData(event.target);
    this.setState({isLoading:true});
    this.state.contract.methods.addIssuerData(data.get('issuerDesc').toString()).send({from:this.state.accountAddress}).then((receipt)=>{
      this.LoadIssuer();
      this.setState({isLoading:false});
    }).catch((err)=>{
      this.ShowError(err.message);
      this.setState({isLoading:false});
    })
  }

  GoBackToEdit = ()=>{
    this.setState({orgDescAdded:this.state.issuerDesc.length > 0});
  }

  AddOrgDesc = ()=>{
  return (
    <span>
    {this.state.issuerDesc.length ? <p onClick={this.GoBackToEdit} style={{marginLeft:'24pc', cursor:'pointer', fontWeight:'bold',position:'absolute'}} title="go back">X</p> : null}
    <br />
    <form onSubmit={this.AddOrgDescOnClick}>
      <input style={{marginLeft: '27pc',width:this.getButtonWidth()}} type="text" id="issuerDesc" name="issuerDesc"
            placeholder="your/issuer org description, public on blockchain" minLength="3" maxLength="50" required />
      <button id = "issuerDescSubmit" type="submit" style={{marginLeft:'1pc',height:'30px',fontSize:'13px'}} className="btn btn-light" >Add Your / Issuer Org Description</button>
    </form>
    </span>
  );
  }

  EditOrgDescOnClick = ()=>{
    this.setState({orgDescAdded:false});
  }

  EditOrgDesc = ()=>{
    return(
        <span id="currentIssuerDesc" style = {{marginLeft: '38pc', fontSize:'13px'}}> Greetings, <span style = {{fontWeight:'bold'}} >{this.state.issuerDesc}</span>
        <button onClick={this.EditOrgDescOnClick} style = {{marginLeft: '2pc', marginTop:'1pc', fontSize:'10px', paddingTop:'0px', paddingBottom:'0px'}} id = "issuerDescEdit" type="submit" className="btn btn-light" >Edit Your / Issuer Org Description</button>
        </span>
      );
  }


  CommonInput = () => {
    return(
      <form onSubmit={this.InputAdded}>
      <br />
    {(!this.state.isLoading && this.state.showAddSpecific && this.state.orgDescAdded) ? <span><input style={{marginLeft: '30pc', width:this.getButtonWidth()}} type="text" id="docDesc0" name="docDesc0"
           placeholder="document description, public on blockchain" minLength="10" maxLength="50"/><br /><br/><input style={{marginLeft: '30pc', width:this.getButtonWidth()}} type="text" id="docIssuee0" name="docIssuee0"
           placeholder="issuee address, optional, prefixed with '0x'" minLength="42" maxLength="42" /></span>: null}
    <div>
    <input style={{marginLeft: '40pc', marginTop:'1pc'}} type="file" id="docid" name="doc" accept="application/pdf, application/JSON" required multiple/><br/>
    {(this.state.showAddSpecific && this.state.orgDescAdded) ?
    <span style={{marginLeft:'40pc',fontSize:'12px'}}>  Have multiple files?, try adding json,
    <span onMouseOver={()=>{this.setState({showJSONformat:true})}} 
          onMouseOut={()=>{this.setState({showJSONformat:false})}}
          style={{color: 'teal',cursor: 'pointer',fontSize:'12px',fontWeight:'bold', marginRight:'10px'}}>hover for format</span></span> :
    <span style={{color: 'teal',fontSize:'12px',fontWeight:'bold',marginLeft:"40pc"}}>select one or more than one files</span>
    }
    <br />
    <button id = "doc0Submit" type="submit" style={{width:this.getButtonWidth(200), marginLeft:'35pc', marginTop: '1pc'}}
            className="btn btn-outline-dark">Submit</button>
    </div>
    </form>
    );
  }

  GetExtn = (name)=>{
    return name.split('.').pop().toLowerCase();
  }


  AddColumn = (column , locked)=>{
    let style={}, onclc = ()=>{}, toolTip = "";

    if (locked){
      style = {
        color: 'gold',
        fontWeight:'bold',
        cursor:'pointer',
        paddingLeft:'40px'
      }
      toolTip = "unlock for only "+ Web3.utils.fromWei(this.state.descPrice)+" eth";
    }

    if(locked){
      return(<td style={style}><ReactTooltip/><div onClick={this.PremiumAddition} data-tip={toolTip}>{column}</div></td>);
    }
    else{
      return(<td style={{paddingLeft:'40px'}}>{column}</td>);
    }
  }

  AddRow = (columns)=>{
    let row = [];
    for (let Idx = 0; Idx < columns.length; Idx++){
      let locked = false;
      if (!this.state.isPremium && (Idx === 3 || Idx === 4) && columns[Idx] === "unlock"){
        locked = true;
      }
      row.push(this.AddColumn(columns[Idx], locked))
    }
    return (<tr>{row}</tr>);
  }

  DisplayTable = ()=>{
    let data = this.state.outputTableData;
    let outHtml=[];
    data.forEach(subdata=>outHtml.push(this.AddRow(subdata)));
    return (outHtml);
  }
  
  IsShaAlreadyAdded = async (issuer, sha, docdesc)=>{
    if (this.state.contract === null){
      return;
    }

    let shaMapped = await this.state.contract.methods.getShaMapping(sha).call();
    for(let Idx = 0; Idx < shaMapped.length; Idx++){
      if(shaMapped[Idx].issuer.toLowerCase() === issuer.toLowerCase() && shaMapped[Idx].desc === docdesc){
        return true;
      }
    }
    return false;
  }

  ReadOneFile = (obj, flag)=>{
    return new Promise((resolve, reject) => {
    var fr = new FileReader({type:"application/json"});  
    fr.onload = () => {
      resolve(fr.result)
    };
    fr.onerror = reject;
    if(flag){
      fr.readAsBinaryString(obj);
    }else{
    fr.readAsText(obj);
    }
  });
  }

  GetFileSha = async (file)=>{
    //check if locked
    //check size to be greater than 0
    if(file.size > 104857600){
      this.ShowError("size of "+file.name+" exceeds 100 MB!");
      return false;
    }
    if(file.size === 0){
      this.ShowError("size of "+ file.name + " is 0 MB, please recheck input.");
      return false;
    }

    let pdfData;
    try {
      pdfData = await this.ReadOneFile(file, true);
    }
    catch(e){
      this.ShowError("Try adding again " + e.target.error.message);
      return false;
    }
    let encryptIdx = pdfData.indexOf("trailer");

    if(encryptIdx !== -1){
      encryptIdx = pdfData.substring(encryptIdx).indexOf("R/Encrypt");
      if(encryptIdx !== -1){
        this.ShowError(file.name+" password protected. Password protected files are not supported yet.",6);
        return false;
      }
    }
    pdfData += file.size.toString();
    let sha, shaHex=[];
    try{
      sha = await sha256(pdfData);
    }
    catch(e){
      this.ShowError("error while computing sha: " + e.target.error.message);
      return false;
    }
    sha.words.forEach((subSha, Idx)=>{shaHex.push((subSha>>>0).toString(16))});
    let sha_O = shaHex.join('');

    return Web3.utils.toBN(sha_O);
  }

  //important function.
  InputAdded = async (event) => {
    this.state.submitevent = event;

    event.preventDefault();

    if(this.state.contract === null){
      window.location.reload();
      return;
    }

    const data  = new FormData(event.target);
    let docs = data.getAll('doc');
    let docDesc0 = data.get('docDesc0');
    let issueeDoc0 = data.get('docIssuee0');
    let JSONfile = [];
    let allDocs = [];
    let FileNameMappedToIdx={};
    let outTableData=[];
    let alreadyAdded = [];
    let notAdded = []; 


    for (let i = 0; i < docs.length; i++){
      let extn = this.GetExtn(docs[i].name);

      if (extn === 'json'){
        if(JSONfile.length === 0){
          JSONfile.push(docs[i]);
        }
        else{
          this.ShowError("multiple JSON files added, at max one accepted");
          return;
        }
      }
      else if (extn === 'pdf'){
        if(FileNameMappedToIdx[docs[i].name] !== undefined){
          this.ShowError("two files cannot be with same name");
          return;
        }
        FileNameMappedToIdx[docs[i].name] = i;
        allDocs.push(docs[i]);
      }
      else{
        this.ShowError(docs[i].name + " only pdf/JSON file(s) accepted", 6);
        return;
      }
    }


    if(allDocs.length === 0){
      this.ShowError("no pdf added");
      return;
    }

    if(this.state.showAddSpecific){
      let allSummary = {shaArr:[], docDesc:[], fileName:[], issueeArr:[]};

      //sanity
      if(allDocs.length === 1 && JSONfile.length === 0){
        if (docDesc0.length === 0){
          this.ShowError("document description has to be added for the lone pdf", 6);
          return;
        }

        let isIssueeOk = Web3.utils.isAddress(issueeDoc0);
        if(issueeDoc0.length > 0 && !isIssueeOk){
          this.ShowError("invalid issuee address, please ensure prefix '0x' added", 6);
          return;
        }
        let temp = await this.GetFileSha(allDocs[0]);
        if(temp === false){
          event.target.reset();
          return;
        }
        let isPresent = await this.IsShaAlreadyAdded(this.state.accountAddress, temp, docDesc0);
        if (!isPresent){
          allSummary.docDesc.push(docDesc0);
          allSummary.issueeArr.push(isIssueeOk ? issueeDoc0: "0x"+("0").repeat(40));
          allSummary.fileName.push(allDocs[0].name);
          allSummary.shaArr.push(temp);
        }
        else{
          this.ShowError("document already present");
          return;
        }
      }
      else{
          let JSONdata;
           if (allDocs.length > 1 && JSONfile.length === 0){
                this.ShowError("JSON file not added");
                return;
          }
          //read json

          try{
            JSONdata = await this.ReadOneFile(JSONfile[0], false);
            
          }
          catch(e){
            this.ShowError("It seems file was modified, try adding again");
            event.target.reset();
            return;
          }
          try{
          JSONdata = JSON.parse(JSONdata);
          }
          catch(e){
            this.ShowError(e.message,6);
            return;
          }

          let reconJSON = {};
          JSONdata.forEach((element)=>{reconJSON[element.docName] = {"docDesc":element.docDesc, "docIssuee":element.docIssuee}});
          for (let Idx = 0; Idx < allDocs.length; Idx++){
            let filename = allDocs[Idx].name;
            if(!(filename in reconJSON)){
              this.ShowError("description of "+filename+" not present in JSON file")
              return;
            }
            let subJSON = reconJSON[filename];
            
            if(subJSON.docDesc === undefined){
              this.ShowError("description not defined for doc: "+filename+" in JSON",6);
              return;
            }else if(subJSON.docDesc.length < 10){
              this.ShowError("description for doc: "+filename+" to be at least 10 char",6);
              return;
            }else  if(subJSON.docDesc.length > 50){
              this.ShowError("description for doc: "+filename+" to be at most 50 char",6);
              return;
            }

            let isIssueeOk = Web3.utils.isAddress(subJSON.docIssuee);
            if(subJSON.docIssuee !== undefined){
                if(subJSON.docIssuee.length > 0 && !isIssueeOk){
                this.ShowError("invalid issuee address for doc: "+ filename +"in JSON file, please ensure prefix '0x' added",6);
                return;
              }
            }

            let temp = await this.GetFileSha(allDocs[Idx]);
            if(temp === false){
              event.target.reset();
              return;
            }
            let isPresent = await this.IsShaAlreadyAdded(this.state.accountAddress, temp, subJSON.docDesc);
            if (!isPresent){
              allSummary.issueeArr.push( isIssueeOk ? subJSON.docIssuee : "0x"+("0").repeat(40));
              allSummary.fileName.push(filename);
              allSummary.docDesc.push(subJSON.docDesc);
              allSummary.shaArr.push(temp);
            }
            else {
              alreadyAdded.push(filename);
            }
          }
          //create summary
      } 
      if(alreadyAdded.length === allDocs.length){
        this.ShowSuccess("all docs already present on ethereum");
        return;
      }

      this.setState({isLoading:true});
      

      this.state.contract.methods.addMultipleDocs(allSummary.shaArr, this.state.accountAddress, allSummary.issueeArr, allSummary.docDesc).
          send({from:this.state.accountAddress}).
          then((receipt)=>{
            this.setState({isLoading:false});
            if(alreadyAdded.length === 0){
              this.ShowSuccess("docs added successfully, verify documents now 🎉🎉", 10);
            }
            else if(alreadyAdded.length < allDocs.length){
              this.ShowSuccess((allDocs.length - alreadyAdded.length).toString() + " docs added successfully, rest "+alreadyAdded.length.toString()+" already added, check sha on ethereum", 6);
            }
            event.target.reset();
          }).catch((err)=>{
            this.setState({isLoading:false});
            this.ShowError(err.message);
            event.target.reset();
          });     
      //show result
    }

    if (this.state.showCheckSpecific){
      
      let output = [];
      //process
      if(allDocs.length === 0){
        this.ShowError("no pdfs added");
        return;
      }
      this.setState({isLoading:true});

      if(allDocs.length === 1){
        let sha = await this.GetFileSha(allDocs[0]);
        if(sha === false){
          this.setState({isLoading:false});
          event.target.reset();
          return;
        }
        output = [{"docData" : await this.state.contract.methods.isDocPresent(sha, this.state.accountAddress).call()}]
      }
      else{
        let shaArr = [];
        for (let Idx = 0; Idx < allDocs.length; Idx++){
          let temp = await this.GetFileSha(allDocs[Idx]);
          if(temp === false){
            this.setState({isLoading:false});
            event.target.reset();
            return;
          }
          shaArr.push(temp);
        }
        output = await this.state.contract.methods.isMultipleDocPresent(shaArr, this.state.accountAddress).call();
      }

      if(output.length !== allDocs.length){
        this.setState({isLoading:false});
        this.ShowError("unexpected error on blockchain, try again later");
        return;
      }
      
      for (let i=0;i<allDocs.length;i++){
        let filename = allDocs[i].name;
        let issuerAdd = "not found";
        let issuerDesc = "not found";
        let docDesc = "not found";
        if(filename.length > 20){
          filename = filename.substring(0,20)+"...";
        }
        if(output[i].docData.length === 0){
          outTableData.push([(i+1).toString(),filename, issuerAdd, issuerDesc, docDesc]);
          continue;
        }
        for (let j = 0; j < output[i].docData.length; j++){
          issuerAdd = output[i].docData[j].issuer.toLowerCase();
          if(this.state.isPremium){
            issuerDesc = output[i].docData[j].issuerDesc;
            docDesc = output[i].docData[j].docDesc;
          }
          else{
            issuerDesc = "unlock";
            docDesc = "unlock";
          }
          outTableData.push([(i+1).toString(),filename, issuerAdd, issuerDesc, docDesc]);
        }
      }
      this.setState({outputTableData:outTableData, outputTable:true});
      this.setState({isLoading:false});
      //show result
    }
  }


  SendEthToAuthor = async ()=>{

    if(window.ethereum === undefined){
      window.alert('install metamask wallet');
      return;
    }
    if (this.state.contract === null){
      return;
    }
    let result = await window.ethereum.request({  method:'eth_sendTransaction',
                                                  params:[{from:this.state.accountAddress,
                                                          to: this.state.contractAddress,
                                                          gas: (32000).toString(16),
                                                          value: (this.state.askVal).toString(16)}]}).catch((err)=>{
                                                            if(err.message.indexOf("out of gas") !== -1){
                                                              this.ShowError("not enough gas, please reconfigure in metamask and try again",6);
                                                            }
                                                          });
  }

  PremiumAddition = async ()=>{
    if (this.state.contract === null){
      return;
    }
    
    this.setState({isLoading:true});
    this.state.contract.methods.addDescAddress().send({from:this.state.accountAddress,
                                                       value:this.state.descPrice,
                                                     }).then((receipt)=>{
                                                              this.LoadPremium();
                                                              this.LoadContractBalance();
                                                              this.setState({isLoading:false});
                                                              if (this.state.submitevent !== null){
                                                              this.InputAdded(this.state.submitevent);
                                                            }
                                                     }).catch((err)=>{
                                                     	  this.setState({isLoading:false});
                                                        this.ShowError(err.message);
                                                     });
  }

  DescPriceChange = async (event)=>{
    if (this.state.contract === null){
      return;
    }
    event.preventDefault();
    const data = new FormData(event.target);
    this.state.contract.methods.updateDescPrice(Web3.utils.toBN(Number(data.get('newPrice')))).send({from:this.state.accountAddress}).then((receipt)=>{
      this.LoadDescPrice();
    }).catch((err)=>{
        this.ShowError(err.message);
    });
  }

  TransferEthToAdmin = ()=>{
    if (this.state.contract === null){
      return;
    }
    this.state.contract.methods.transferEthToAdmin().send({from:this.state.accountAddress}).catch((err)=>{this.ShowError(err.message)});
  }

  Destroy = ()=>{
    if (this.state.contract === null){
      return;
    }
    this.state.contract.methods.destroy().send({from:this.state.accountAddress}).catch((err)=>{this.ShowError(err.message)});
  }

  AdminArea = ()=>{
    return(<div id="contractBalance" style={{position:'fixed',right:'100px',top:'180px',cursor:'pointer'}} onClick={this.LoadContractBalance}>contract balance:{this.state.contractBalance}
      <button onClick ={this.TransferEthToAdmin} id = "transfer" type="button" className="btn btn-primary" style={{position:'fixed',right:'300px',top:'120px'}} id="transfer">Transfer</button>
      <button onClick={this.Destroy} id = "destroy" type="button" className="btn btn-primary" style={{position:'fixed',right:'50px',top:'120px'}} id="destroy">Destroy</button>
      <div>
      <form onSubmit={this.DescPriceChange}> 
      <input id="updatedPrice" type="number" name="newPrice" style={{position:'fixed',right:'170px',top:'70px'}} placeholder="in wei" />
      <button id = "updPrice" className="btn btn-primary" style={{position:'fixed',right:'170px',top:'120px'}} id="updPrice">Update Price</button>
      </form>
      </div>
      </div> );
  }

  connect = async ()=>{
  	await this.accountAddedPost();
  }



render(){
  return(
    <div>
    {(this.state.accountAddress !== "0x0" && this.state.accountAddress !== "") ? <div id="address" style={{fontSize: '9px',marginRight:'1pc', right:0, position:'absolute',top:'5px'}}> address connected: {this.state.accountAddress}</div>:null}
    {(this.state.accountAddress === "0x0" || this.state.accountAddress === "") ? <div id="address" style={{fontSize: '12px',marginRight:'1pc', right:0, position:'absolute',top:'10px', cursor:"pointer", backgroundColor:"#34495E", borderRadius:'5px', padding:'7px', color:'white',fontFamily:'Arial', fontWeight:'bold'}} onClick={this.connect}> connect to ethereum</div>:null}

  <span style={{marginLeft:'10pc', position:'fixed'}}>
  <button type="submit" className="btn btn-primary btn-md" style={this.state.addButtonStyle}
  onMouseOver={()=>{ let copy = JSON.parse(JSON.stringify(this.state.addButtonStyle));
                           copy.color = 'black';
                           copy.borderColor = 'grey';
                           this.setState({addButtonStyle:copy}); }} 
  onMouseOut={()=>{ let color = this.state.showAddSpecific ? 'darkorange' : 'gray';
                    let copy = JSON.parse(JSON.stringify(this.state.addButtonStyle));
                           copy.color = color;
                           copy.borderColor = 'lightgrey';
                           this.setState({addButtonStyle:copy}); }}
  onClick={this.onClickAdd}>Add Document Hash To Ethereum</button></span>
  
  <span style={{position:'fixed', right:'0', marginRight:'10pc'}}>
  <button type="submit" className="btn btn-primary btn-md" style={this.state.checkButtonStyle}
  onMouseOver={()=>{ let copy = JSON.parse(JSON.stringify(this.state.checkButtonStyle));
                           copy.color = 'black';
                           copy.borderColor = 'grey';
                           this.setState({checkButtonStyle:copy}); }} 
  onMouseOut={()=>{ let color = this.state.showCheckSpecific ? 'darkorange' : 'gray';
                    let copy = JSON.parse(JSON.stringify(this.state.checkButtonStyle));
                           copy.color = color;
                           copy.borderColor = 'lightgrey';
                           this.setState({checkButtonStyle:copy}); }}
  onClick={this.onClickCheck}>Verify Document On Ethereum</button></span>

  {this.state.isLoading ? <div style={{position:"fixed",marginLeft:"30pc",marginTop:"13pc", fontWeight:'bold'}}>connecting to blockchain...please be patient as this can take few seconds...</div> : null}
  <br />
  <div style={{marginTop:'5pc'}}>
  <div>
  {(this.state.showAddSpecific && !this.state.orgDescAdded) ? this.AddOrgDesc() : null}
  </div>
  <div>
  {(this.state.showAddSpecific && this.state.orgDescAdded) ? this.EditOrgDesc(): null}
  </div>
  <div>
  {(!this.state.isLoading && this.state.showCommon && (this.state.showCheckSpecific || (this.state.showAddSpecific && this.state.orgDescAdded))) ? this.CommonInput() : null}
  </div>
  </div>
  <div>
  </div>
  {(this.state.isPremium) ? <div style={{fontSize: '14px',marginLeft: '3pc',position:'absolute',top:'40px',right:'20px',fontWeight:'bold',color:'darkorange', pointer:'default'}} >&#9733; premium access: all descriptions enabled</div> : null}
  {(this.state.showCheckSpecific && !this.state.isPremium) ? <button  style={{fontSize: '14px',marginLeft: '3pc',position:'absolute',top:'40px',right:'20px',fontWeight:'bold', cursor: 'pointer'}} className="btn btn-light" onClick={this.PremiumAddition} title="unlocking enables doc and org description on checking sha">☆ lifetime all description views for only {Web3.utils.fromWei(this.state.descPrice)} eth</button>:null}
  {(this.state.isAdmin) ? this.AdminArea():null}
  {(this.state.errorSet) ?<div className="alert alert-secondary" role="alert" style={{height:'20px', textAlign:'center', fontSize:'13px', paddingTop:'0px',bottom:'60px',left:'35pc',position:'fixed'}}>
  Error(s) occured {this.state.errorMsg}
  </div>:null}
  {(this.state.successSet) ?<div className="alert alert-success" role="alert" style={{align:'center', height:'20px', textAlign:'center', fontSize:'13px', paddingTop:'0px',bottom:'60px',left:'40pc',position:'fixed'}}>
  {this.state.successMsg}
  </div>:null}
  {(this.state.showJSONformat && this.state.showAddSpecific) ?
  <div className="card border-dark mb-3" style={{position:'absolute', top:'410px', left:'1050px', fontSize:'11px'}}>
  <div className="card-header">JSON format to add sha</div>
  <div className="card-body">
    <pre><p className="card-text">    &#123;[<br />        &#123;<br />          "docName": &lt;doc0 name with extension &gt;,<br />          "docDesc": &lt; description of doc0 &gt; ,<br />          "docIssuee": &lt; Issuee address, optional field &gt;<br />         &#125;,<br />        &#123;<br />          "docName": &lt; doc1 Name with extension&gt;, <br />          "docDesc": &lt;description of doc1 &gt;, <br />          "docIssuee": &lt;Issuee address, optional field &gt; <br />         &#125;, <br />...<br />     ]&#125;<br/><br/>note: docDesc is public on blockchain and <br/>docName accepts pdf only. <br/><b>App will map docName to chosen files' name and <br/>assign docDesc.Please make sure to select all files<br/>with descriptions in JSON along with JSON file itself.</b></p></pre>
  </div>
 </div> :null}
 <div>{(!this.state.isLoading && this.state.outputTable && this.state.showCheckSpecific) ? <table id = "checkResults" style={{marginLeft: '10pc',fontSize: '12px', marginTop:'3pc'}}>

      <tr><th style={{paddingLeft:'40px'}}>#</th><th style={{paddingLeft:'40px'}}>Document</th><th style={{paddingLeft:'40px'}}>Issuer Address</th><th style={{paddingLeft:'40px'}}>Issuer description</th><th style={{paddingLeft:'40px'}}>Doc Description </th></tr>
      {this.DisplayTable()}
    </table> : null}</div>
  </div>
  );
  }
}



ReactDOM.render(<Ultima />, document.querySelector("#Everything"));


function App() {
  return (
    <div className="App">
    </div>
    );
  }

export default App;